import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IFiyatList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IFiyatList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  spFiyatList: any[];
  spFiyatList_dummy: any[];
  spFiyatListCount: any[];
  spFiyatListCount_dummy: any[];
  getFilterParametersByUserName: any[];
  getFilterParametersByUserName_dummy: any[];
  getListFiyatListeAd: any[];
  getListFiyatListeAd_dummy: any[];
  sbProjeTuruList: any[];
  sbProjeTuruList_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  FilterParametersSaveFiyatList: number;
  FilterParametersSaveFiyatList_dummy: number;
  DeleteFiyatListe: number;
  DeleteFiyatListe_dummy: number;
  isCompfiyatlist_642953AuthorizationVisible: "visible" | "hidden";
  isCompfiyatlist_999496AuthorizationVisible: "visible" | "hidden";
  isCompfiyatlist_646957AuthorizationVisible: "visible" | "hidden";
  isCompfiyatlist_209203AuthorizationVisible: "visible" | "hidden";
}

export class FiyatList_ScreenBase extends React.PureComponent<IFiyatList_ScreenProps, any> {
  fiyatlist_149221_value_kuikaSelectBoxRef: React.RefObject<any>;
  fiyatlist_65965_value_kuikaSelectBoxRef: React.RefObject<any>;
  fiyatlist_181000_value_kuikaTableRef: React.RefObject<any>;
  fiyatlist_775365_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "9dc04710-6b60-4493-b4b3-9bd928442f0e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 513636, PropertyName: "value", Value: "Fiyat Listeleri" },
        { Id: 149221, PropertyName: "placeholder", Value: "Fiyat Listesi Seçiniz..." },
        { Id: 65965, PropertyName: "placeholder", Value: "Proje Türü Seçiniz..." },
        { Id: 725438, PropertyName: "placeholder", Value: "Aranacak kelimeyi yazınız..." },
        { Id: 181000, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 295011, PropertyName: "title", Value: "Proje Türü" },
        { Id: 245227, PropertyName: "value", Value: "[datafield:projeturuuzun]" },
        { Id: 668335, PropertyName: "title", Value: "Bölge" },
        { Id: 889613, PropertyName: "value", Value: "[datafield:bolge]" },
        { Id: 252835, PropertyName: "title", Value: "Mecra" },
        { Id: 538790, PropertyName: "value", Value: "[datafield:mecra]" },
        { Id: 657249, PropertyName: "title", Value: "Fiyatlandırma Tipi" },
        { Id: 756239, PropertyName: "value", Value: "[datafield:fiyatlandirmatipi]" },
        { Id: 103671, PropertyName: "title", Value: "Tam B.Fiyat" },
        { Id: 618908, PropertyName: "value", Value: "[datafield:fiyat]" },
        { Id: 174841, PropertyName: "title", Value: "İndirimli B.FİYAT" },
        { Id: 811297, PropertyName: "value", Value: "[datafield:fiyatindirimli]" },
        { Id: 859693, PropertyName: "title", Value: "P.Birim" },
        { Id: 672320, PropertyName: "value", Value: "[datafield:simge]" },
        { Id: 479562, PropertyName: "title", Value: "Birim Sn" },
        { Id: 569129, PropertyName: "value", Value: "[datafield:snmodblock]" },
        { Id: 4075, PropertyName: "title", Value: "Min Sn" },
        { Id: 804436, PropertyName: "value", Value: "[datafield:snesitkucuk]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.fiyatlist_149221_value_kuikaSelectBoxRef = React.createRef();
    this.fiyatlist_65965_value_kuikaSelectBoxRef = React.createRef();
    this.fiyatlist_725438_value_kuikaTextInputRef = React.createRef();
    this.fiyatlist_181000_value_kuikaTableRef = React.createRef();
    this.fiyatlist_775365_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      spFiyatList: [],
      spFiyatListCount: [],
      getFilterParametersByUserName: [],
      getListFiyatListeAd: [],
      sbProjeTuruList: [],
      SetValueOf: "",
      FilterParametersSaveFiyatList: "",
      DeleteFiyatListe: "",
      isCompfiyatlist_642953AuthorizationVisible: "visible",
      isCompfiyatlist_999496AuthorizationVisible: "visible",
      isCompfiyatlist_646957AuthorizationVisible: "visible",
      isCompfiyatlist_209203AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("fiyatlist", "");
      return;
    }

    if (true) {
      await this.FiyatListPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompfiyatlist_642953AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Admin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompfiyatlist_999496AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Admin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompfiyatlist_646957AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Admin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompfiyatlist_209203AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Admin"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("fiyatlist", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("fiyatlist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.FiyatListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      fiyatlist_149221_value: this.state.getFilterParametersByUserName?.at?.(0)?.fiyatListSBox1 ?? undefined,
      fiyatlist_65965_value: this.state.getFilterParametersByUserName?.at?.(0)?.fiyatListSBox2 ?? undefined,
      fiyatlist_725438_value: this.state.getFilterParametersByUserName?.at?.(0)?.fiyatListSearch ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  FiyatListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FiyatList/FiyatListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getFilterParametersByUserName = result?.data.getFilterParametersByUserName;
    formVars.fiyatlist_149221_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].fiyatListSBox1
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].fiyatListSBox1
        : null;
    formVars.fiyatlist_149221_options = stateVars.getListFiyatListeAd;
    formVars.fiyatlist_65965_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].fiyatListSBox2
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].fiyatListSBox2
        : null;
    formVars.fiyatlist_65965_options = stateVars.sbProjeTuruList;
    formVars.fiyatlist_725438_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].fiyatListSearch
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].fiyatListSearch
        : null
    );
    stateVars.getListFiyatListeAd = result?.data.getListFiyatListeAd;

    formVars.fiyatlist_149221_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].fiyatListSBox1
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].fiyatListSBox1
        : null;
    formVars.fiyatlist_149221_options = stateVars.getListFiyatListeAd;
    stateVars.sbProjeTuruList = result?.data.sbProjeTuruList;

    formVars.fiyatlist_65965_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].fiyatListSBox2
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].fiyatListSBox2
        : null;
    formVars.fiyatlist_65965_options = stateVars.sbProjeTuruList;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("fiyatlist_149221_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getFilterParametersByUserName?.length > 0
          ? stateVars.getFilterParametersByUserName[0].fiyatListSBox1
          : this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0].fiyatListSBox1
          : null
      ),
      null,
      "Id"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("fiyatlist_65965_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getFilterParametersByUserName?.length > 0
          ? stateVars.getFilterParametersByUserName[0].fiyatListSBox2
          : this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0].fiyatListSBox2
          : null
      ),
      null,
      "Id"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("fiyatlist_725438_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getFilterParametersByUserName?.length > 0
          ? stateVars.getFilterParametersByUserName[0].fiyatListSearch
          : this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0].fiyatListSearch
          : null
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatListPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  FiyatListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeTuruId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeTuruId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FiyatList/FiyatListPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spFiyatList = result?.data.spFiyatList;

    stateVars.spFiyatListCount = result?.data.spFiyatListCount;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatListPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.fiyatlist_149221_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].fiyatListSBox1
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].fiyatListSBox1
        : null
    );

    stateVars.dataSource_149221 = this.state.getListFiyatListeAd;
    stateVars.dataSource_149221 = this.state.getListFiyatListeAd;
    formVars.fiyatlist_65965_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].fiyatListSBox2
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].fiyatListSBox2
        : null
    );

    stateVars.dataSource_65965 = this.state.sbProjeTuruList;
    stateVars.dataSource_65965 = this.state.sbProjeTuruList;
    formVars.fiyatlist_725438_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].fiyatListSearch
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].fiyatListSearch
        : null
    );

    stateVars.dataSource_181000 = this.state.spFiyatList;
    formVars.fiyatlist_775365_total = ReactSystemFunctions.value(
      this,
      stateVars.spFiyatListCount?.length > 0
        ? stateVars.spFiyatListCount[0].sumCount
        : this.state.spFiyatListCount?.length > 0
        ? this.state.spFiyatListCount[0].sumCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  FiyatListComponent_798391_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "FiyatList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "294787",
      ReactSystemFunctions.convertToTypeByName("", "string"),
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatListComponent_642953_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "FiyatArttir",
      "FiyatListeId",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "FiyatArttir",
      "FiyatListeAd",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "tanim")
        ),
        "string"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.FiyatListComponent_642953_onClick1_,
      "FiyatList",
      "FiyatArttir",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "303848",
      null,
      null,
      "true",
      "400px",
      "20vh",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  FiyatListComponent_642953_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeTuruId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FiyatList/FiyatListComponent_642953_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spFiyatList = result?.data.spFiyatList;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatListComponent_642953_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatListComponent_642953_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_181000 = this.state.spFiyatList;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatListComponent_999496_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "FiyatKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.FiyatListComponent_999496_onClick1_,
      "FiyatList",
      "FiyatKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "340992",
      null,
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  FiyatListComponent_999496_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeTuruId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeTuruId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FiyatList/FiyatListComponent_999496_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spFiyatList = result?.data.spFiyatList;

    stateVars.spFiyatListCount = result?.data.spFiyatListCount;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatListComponent_999496_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatListComponent_999496_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_181000 = this.state.spFiyatList;
    formVars.fiyatlist_775365_total = ReactSystemFunctions.value(
      this,
      stateVars.spFiyatListCount?.length > 0
        ? stateVars.spFiyatListCount[0].sumCount
        : this.state.spFiyatListCount?.length > 0
        ? this.state.spFiyatListCount[0].sumCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatListComponent_149221_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FiyatListSBox1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "string"
      ),
      FiyatListSBox2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "string"
      ),
      FiyatListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeTuruId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      ),
      Search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeTuruId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FiyatList/FiyatListComponent_149221_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveFiyatList = result?.data.filterParametersSaveFiyatList;
    stateVars.spFiyatList = result?.data.spFiyatList;

    stateVars.spFiyatListCount = result?.data.spFiyatListCount;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatListComponent_149221_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatListComponent_149221_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("fiyatlist_775365_value", "string"),
      ReactSystemFunctions.convertToTypeByName("1", "any"),
      null
    );

    stateVars.dataSource_181000 = this.state.spFiyatList;
    formVars.fiyatlist_775365_total = ReactSystemFunctions.value(
      this,
      stateVars.spFiyatListCount?.length > 0
        ? stateVars.spFiyatListCount[0].sumCount
        : this.state.spFiyatListCount?.length > 0
        ? this.state.spFiyatListCount[0].sumCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatListComponent_65965_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FiyatListSBox1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "string"
      ),
      FiyatListSBox2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "string"
      ),
      FiyatListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeTuruId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      ),
      Search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeTuruId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FiyatList/FiyatListComponent_65965_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveFiyatList = result?.data.filterParametersSaveFiyatList;
    stateVars.spFiyatList = result?.data.spFiyatList;

    stateVars.spFiyatListCount = result?.data.spFiyatListCount;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatListComponent_65965_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatListComponent_65965_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("fiyatlist_775365_value", "string"),
      ReactSystemFunctions.convertToTypeByName("1", "any"),
      null
    );

    stateVars.dataSource_181000 = this.state.spFiyatList;
    formVars.fiyatlist_775365_total = ReactSystemFunctions.value(
      this,
      stateVars.spFiyatListCount?.length > 0
        ? stateVars.spFiyatListCount[0].sumCount
        : this.state.spFiyatListCount?.length > 0
        ? this.state.spFiyatListCount[0].sumCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatListComponent_725438_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FiyatListSBox1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "string"
      ),
      FiyatListSBox2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "string"
      ),
      FiyatListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeTuruId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      ),
      Search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeTuruId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FiyatList/FiyatListComponent_725438_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveFiyatList = result?.data.filterParametersSaveFiyatList;
    stateVars.spFiyatList = result?.data.spFiyatList;

    stateVars.spFiyatListCount = result?.data.spFiyatListCount;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatListComponent_725438_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatListComponent_725438_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("fiyatlist_775365_value", "string"),
      ReactSystemFunctions.convertToTypeByName("1", "any"),
      null
    );

    stateVars.dataSource_181000 = this.state.spFiyatList;
    formVars.fiyatlist_775365_total = ReactSystemFunctions.value(
      this,
      stateVars.spFiyatListCount?.length > 0
        ? stateVars.spFiyatListCount[0].sumCount
        : this.state.spFiyatListCount?.length > 0
        ? this.state.spFiyatListCount[0].sumCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatListComponent_646957_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "fiyatlist_181000_value", "id"),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeTuruId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "7a80c560_0dba_8814_5c08_748f198a7f9f_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "FiyatList/FiyatListComponent_646957_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DeleteFiyatListe = result?.data.deleteFiyatListe;
      stateVars.spFiyatList = result?.data.spFiyatList;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.FiyatListComponent_646957_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatListComponent_646957_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_181000 = this.state.spFiyatList;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatListComponent_209203_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "FiyatKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "fiyatlist_181000_value", "id"), "Guid")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.FiyatListComponent_209203_onClick1_,
      "FiyatList",
      "FiyatKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "938654",
      null,
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  FiyatListComponent_209203_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeTuruId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FiyatList/FiyatListComponent_209203_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spFiyatList = result?.data.spFiyatList;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatListComponent_209203_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatListComponent_209203_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_181000 = this.state.spFiyatList;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatListComponent_775365_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_149221_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_725438_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeTuruId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_65965_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlist_775365_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FiyatList/FiyatListComponent_775365_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spFiyatList = result?.data.spFiyatList;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatListComponent_775365_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatListComponent_775365_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_181000 = this.state.spFiyatList;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [294787, 303848, 340992, 938654] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.FiyatListPageInit();
    }
    if (diId == 303848) {
      isErrorOccurred = await this.FiyatListComponent_642953_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 340992) {
      isErrorOccurred = await this.FiyatListComponent_999496_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 938654) {
      isErrorOccurred = await this.FiyatListComponent_209203_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
